.Button {
  outline: none;
  cursor: pointer;
  margin: 10px;
}

.Danger {
  border: none;
  background-color: #ff3333;
  color:#fff;
  font-weight: 400;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
}

.Cancel {
  background-color: #fff;
  border: 1px solid #d6d4d4;
  color: #757575;
  font-weight: 400;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
}
