.urlInput {
  border: none;
  outline: none;
}

.urlInput:focus,
.urlInput:active {
  border: none;
  outline: none;
}
