.creatives-image {
    position: relative;
}

.remove-creative-image {
    position: absolute;
    background-color: #3a7e89;
    font-size: 11px;
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    font-weight: 300;
}