.Modal {
  position: fixed;
  z-index: 500;
  background-color: #fff;
  width: 70%;
  border: 1px solid #ccc;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: 5px;
}

.ModalHeader {
  border-bottom: 1px solid #d6d4d4;
  padding: 15px;
  font-size: 17px;
}

.ModalContent {
  font-size: 14px;
  color: #757575;
  padding: 15px;
  margin-top: 25px;
}

.ModalFooter {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
